import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { getWebAssetsUrl, AssetsAppName } from '@utils/web-assets';

export interface AssetIconProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  appName: AssetsAppName | undefined;
  path: string;
  hoverPath?: string;
  disabledPath?: string;
  disabled?: boolean;
  className?: string;
}

const AssetIcon = ({
  appName,
  path,
  hoverPath,
  disabled = false,
  disabledPath,
  className = '',
  ...props
}: AssetIconProps) => {
  const iconSrc = getWebAssetsUrl(path, appName);
  const hoverIconSrc = hoverPath && getWebAssetsUrl(hoverPath, appName);
  const disabledPathSrc =
    disabled && disabledPath && getWebAssetsUrl(disabledPath, appName);

  return disabled ? (
    <img
      src={disabledPathSrc as string}
      className={ivclass(className, 'hover:iv-cursor-disabled')}
      alt="iv-icon-disabled"
      {...props}
    />
  ) : (
    <>
      <img
        src={iconSrc}
        className={ivclass(className, {
          'group-hover:iv-hidden': !!hoverIconSrc,
        })}
        alt="iv-icon"
        {...props}
      />
      {!!hoverIconSrc && (
        <img
          src={hoverIconSrc}
          className={ivclass(className, 'iv-hidden group-hover:iv-block')}
          alt="iv-icon-hover"
          {...props}
        />
      )}
    </>
  );
};

export default AssetIcon;
