import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import PlayIcon from '@components/icons/PlayIcon';
import TutorialVideoModal from '@components/modal/tutorial-video-modal/TutorialVideoModal';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import { getYoutubeEmbedLink, getYoutubePreviewUrl } from '@utils/common.utils';
import { useState } from 'react';
import { PosterType, TutorialVideo } from 'src/types';

interface TutorialVideoProps {
  videoData: TutorialVideo;
  browser: string;
  platform: PlatformType;
}

function TutorialVideo(props: TutorialVideoProps) {
  const { videoData, browser, platform } = props;
  const { videoPoster, title, embedLink } = videoData;

  const poster: PosterType = videoPoster?.desktop || {
    defaultURL: videoPoster?.url,
  };

  const posterURL = getPosterURL(poster, browser);

  const youtubeId = getYoutubeEmbedLink(embedLink || '');

  const youtubePoster = getYoutubePreviewUrl(youtubeId, platform);

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={ivclass(
        'iv-container iv-mx-auto',
        'iv-pt-200 md:iv-pt-100 sm:iv-pt-100',
      )}
    >
      <div
        className={ivclass(
          'iv-grid iv-col-span-12',
          'xl:iv-col-start-1 lg:iv-col-start-1',
          'xl:iv-px-15 lg:iv-px-20',
          'iv-text-center',
          'iv-mb-75 md:iv-mb-50 sm:iv-mb-40',
        )}
      >
        <TitleH2BlockGradient heading={title} />
      </div>
      <div
        className={ivclass(
          'iv-relative',
          'iv-flex iv-justify-center iv-items-center',
          'iv-mx-20',
        )}
      >
        {(posterURL || youtubePoster) && (
          <ImageComponent
            elementype="native"
            src={posterURL || youtubePoster}
            alt="Video Poster"
            loading="lazy"
            className={ivclass(
              'iv-rounded-30 iv-aspect-video iv-object-cover',
              'iv-max-w-7xl iv-w-full',
            )}
          />
        )}

        <button
          className={ivclass(
            'iv-absolute',
            'iv-top-1/2 iv-left-1/2 -iv-translate-x-1/2 -iv-translate-y-1/2',
            'iv-cursor-pointer',
          )}
          aria-label="preview-tutorial"
          type="button"
          onClick={openModal}
        >
          <PlayIcon
            className={ivclass(
              'iv-w-[150px] iv-h-[150px] sm:iv-w-[75px] sm:iv-h-[75px]',
            )}
          />
        </button>
      </div>

      {embedLink && (
        <TutorialVideoModal
          isOpen={isOpen}
          onClose={closeModal}
          embedLink={embedLink}
        />
      )}
    </div>
  );
}

export default TutorialVideo;
