import ivclass from '@invideoio/web-shared/Modules/IVClass';
import AssetIcon from '../asset-icon/AssetIcon';
import { Fragment } from 'react';

enum AssetsAppName {
  COMMON = 'common',
}
const closeIcon = '/icons/close.svg';

export interface BaseModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  maxWidth?: string;
  hideCloseButton?: boolean;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
  CustomCloseIcon?: React.FC<{ onClose: Function }>;
}

const BaseModal = ({
  isOpen,
  onClose,
  children,
  maxWidth = '600px',
  hideCloseButton = false,
  containerStyle = {},
  containerClassName = '',
  CustomCloseIcon,
}: BaseModalProps) => {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <>
      {isOpen && (
        <div
          className={ivclass(
            'iv-fixed',
            'iv-top-0 iv-left-0 iv-z-modal',
            'iv-bg-black-35',
            'iv-h-full iv-w-full',
            'iv-overflow-x-hidden',
            'iv-backdrop-blur',
          )}
        >
          <div
            className={ivclass(
              'iv-flex',
              'iv-items-center',
              'iv-h-full iv-min-h-full iv-min-w-full',
            )}
          >
            <div
              className={ivclass(
                'iv-relative',
                'iv-flex',
                'iv-flex-col iv-items-center iv-justify-center',
                'iv-w-full iv-h-full',
                'md:iv-p-20 lg:iv-p-20 xl:iv-p-20',
              )}
            >
              <div
                className={ivclass(
                  'iv-relative',
                  'iv-w-screen',
                  'iv-rounded-[10px]',
                  'iv-bg-grey-0',
                  'iv-shadow-xl',
                  containerClassName,
                )}
                style={{ maxWidth: maxWidth, ...containerStyle }}
              >
                {!hideCloseButton && (
                  <Fragment>
                    {CustomCloseIcon ? (
                      <CustomCloseIcon onClose={handleClose} />
                    ) : (
                      <div
                        onClick={handleClose}
                        className={ivclass(
                          'iv-absolute',
                          'iv-z-10',
                          'iv-top-[10px]',
                          'iv-right-5',
                          'iv-w-28 iv-h-28',
                          'iv-flex iv-justify-center iv-items-center',
                          'iv-rounded-[4px]',
                          'iv-bg-grey-0 hover:iv-bg-grey-10',
                        )}
                      >
                        <AssetIcon
                          className={ivclass('iv-w-[16px] iv-h-[16px]')}
                          appName={AssetsAppName.COMMON}
                          path={closeIcon}
                          alt="close"
                        />
                      </div>
                    )}
                  </Fragment>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BaseModal;
