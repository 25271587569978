import React from 'react';

interface PlayIconProps extends React.SVGProps<SVGSVGElement> {
  rectFillOpacity?: string;
}

function PlayIcon(props: PlayIconProps) {
  const { rectFillOpacity, ...svgProps } = props;
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g filter="url(#filter0)">
        <rect
          width="240"
          height="240"
          rx="120"
          fill="black"
          fillOpacity={rectFillOpacity || '0.25'}
        />
        <path
          d="M90.3902 68.9124C89.0527 68.1402 87.3809 69.1055 87.3809 70.6499V177.321C87.3809 178.865 89.0527 179.83 90.3902 179.058L182.77 125.723C184.107 124.95 184.107 123.02 182.77 122.248L90.3902 68.9124Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0"
          x="-160"
          y="-160"
          width="560"
          height="560"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="80" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_784_242613"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_784_242613"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default PlayIcon;
