import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';
import { DiscoverMore } from 'src/types';

interface DiscoverMoreChipsProps {
  discoverMore: DiscoverMore;
}

function DiscoverMoreChips(props: DiscoverMoreChipsProps) {
  const { discoverMore } = props;
  return (
    <div
      className={ivclass(
        'iv-container iv-max-w-7xl',
        'iv-mx-auto md:iv-px-20 sm:iv-px-20 iv-pt-200 md:iv-pt-150 sm:iv-pt-100',
      )}
    >
      <div
        className={ivclass(
          'iv-flex iv-flex-col',
          'iv-py-30 iv-px-35 sm:iv-px-20 sm:iv-py-15',
          'iv-bg-grey-5 iv-rounded-30 sm:iv-rounded-25',
        )}
      >
        <div className={ivclass('iv-mb-20 sm:iv-mb-5')}>
          <h3
            className={ivclass(
              'iv-text-heading-s sm:iv-text-heading-3xs iv-font-extrabold iv-text-grey-85',
            )}
          >
            Discover more:
          </h3>
        </div>
        <div className={ivclass('iv-flex iv-flex-wrap')}>
          {discoverMore?.Links &&
            discoverMore.Links.map(data => (
              <a
                key={data.id}
                href={data.URL}
                rel="noopener noreferrer"
                target="_blank"
                className={ivclass(
                  'iv-py-5 iv-px-15 iv-mr-10 iv-my-5',
                  'iv-bg-grey-10 iv-rounded-30',
                  'iv-text-body-xl iv-font-semibold iv-text-grey-70',
                )}
              >
                {data.Name}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DiscoverMoreChips;
