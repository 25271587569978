import { getYoutubeEmbedLink } from '@utils/common.utils';
import React from 'react';
import BaseModal from '../BaseModal';

interface TutorialVideoModalProps {
  embedLink: string;
  isOpen: boolean;
  onClose: () => void;
}

function TutorialVideoModal({
  embedLink,
  onClose,
  isOpen,
}: TutorialVideoModalProps) {
  const youtubeId = getYoutubeEmbedLink(embedLink);
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      containerStyle={{
        maxWidth: 'auto',
        backgroundColor: 'transparent',
        height: '100%',
        boxShadow: 'none',
      }}
    >
      <div className="iv-flex iv-justify-center iv-items-center iv-h-full">
        <iframe
          width="854"
          height="480"
          className="iv-aspect-video iv-max-w-4xl iv-h-auto"
          src={`https://youtube.com/embed/${youtubeId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </BaseModal>
  );
}

export default TutorialVideoModal;
