import React, { Fragment } from 'react';
import SEO from './SEO';
import { SEOType } from '../../../types';
import {
  generateJSONLDSchema,
  generateJSONLDSchemaFAQ,
  generateJSONLDSchemaHowTo,
  getBreadcrumbsForPage,
} from '@utils/seo';
import { IVAccordionItemItemProps } from '@invideoio/web-shared/Modules/IVAccordion/types';

interface MakeSEOprops {
  seo: SEOType;
  FAQItems: IVAccordionItemItemProps[];
  url: string;
  HowToSchema?: any[];
  locale?: string;
}

function MakeSEO(props: MakeSEOprops) {
  const { FAQItems, seo, url, HowToSchema, locale } = props;

  // Add page path here to add breadcrumbs
  // Also add corresponding breadcrumb data in src/utils/seo.ts
  const pagesWithBreadcrumbs = ['/online-video-editor'];
  const breadcrumbPage = pagesWithBreadcrumbs.find(p => url.includes(p));
  const breadcrumbs = getBreadcrumbsForPage(breadcrumbPage);

  const HowTo = HowToSchema && generateJSONLDSchemaHowTo(HowToSchema, url);

  const FAQSchema = generateJSONLDSchemaFAQ(FAQItems);

  const full_url = `${
    process.env.NEXT_PUBLIC_BASE_HOST || 'https://invideo.io'
  }${url}`;

  const { softwareApplicationSchema, pageSchema } = generateJSONLDSchema(
    seo,
    full_url,
    'WebPage',
    {
      insertRating: true,
      insertBreadcrumbs: breadcrumbs ?? undefined,
    },
  );

  return (
    <SEO
      title={seo.Title}
      description={seo.Description}
      canonicalurl={seo.CanonicalURL}
      keywords={seo.Keywords}
    >
      {(seo.RobotFollow || seo.RobotIndex) && (
        <meta
          name="robots"
          content={`${seo.RobotFollow || ''}${
            seo.RobotFollow && seo.RobotIndex ? ', ' : ''
          }${seo.RobotIndex || ''}`}
        />
      )}

      <script
        key={'ld-schema-webpage'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(pageSchema) }}
      />

      <script
        key={'ld-schema-softwareapplication'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(softwareApplicationSchema),
        }}
      />

      {FAQSchema && (
        <script
          key={`ld-schema-faq`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(FAQSchema) }}
        />
      )}

      {HowTo &&
        HowTo.map(data => {
          return (
            <script
              key={data.name}
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
            />
          );
        })}

      {Array.isArray(seo.Meta) &&
        seo.Meta.map(meta => (
          <meta key={meta.id} name={meta.Name} content={meta.Content} />
        ))}

      {Array.isArray(seo.Hreflang) &&
        seo.Hreflang.map(hreflink => (
          <link
            key={hreflink.Lang}
            rel="alternate"
            hrefLang={
              hreflink.Lang === 'xdefault' ? 'x-default' : hreflink.Lang
            }
            href={hreflink.Link}
          />
        ))}

      {seo.Hreflang?.length === 0 && (
        <Fragment>
          <link
            key="x-default"
            rel="alternate"
            hrefLang="x-default"
            href={full_url}
          />
          <link
            key={locale || 'en-us'}
            rel="alternate"
            hrefLang={locale === 'en' ? 'en-us' : locale}
            href={full_url}
          />
        </Fragment>
      )}
    </SEO>
  );
}

export default MakeSEO;
